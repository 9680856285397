import React, { useEffect, useState } from "react";
import { ImCross } from "react-icons/im";

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 z-50 bg-black bg-opacity-[68%] flex justify-center items-start overflow-y-auto hide-scrollbar"
      // onClick={onClose}
    >
      <div
        className="relative max-w-[861px] w-[90%] py-5 sm:py-7 mt-[10%] mb-[10%] shadow-lg"
        style={{
          backgroundColor: "#FFFFFF",
          borderRadius: "25px",
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <button
          className="absolute z-50 top-4 right-4 bg-grayLight rounded-full w-[22px] h-[22px] sm:w-[35px] sm:h-[35px] flex items-center justify-center"
          onClick={onClose}
        >
          <ImCross size={12} color="white" />
        </button>
        {children}
      </div>
    </div>
  );
};

export default Modal;
