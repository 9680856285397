import { configureStore } from "@reduxjs/toolkit";
import accountReducer from "../redux/accounts/accountSlice";
import cardReducer from "../redux/cards/cardSlice";

export const store = configureStore({
  reducer: {
    account: accountReducer,
    card: cardReducer,
  },
});
