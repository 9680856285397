import React from "react";
import curvedLine from "../../assets/images/curvedLine.svg";

const HomeText = () => {
  return (
    <div>
      <h1>
        The Best Way to <br />
        Add{" "}
        <span className="relative inline-block text-primary">
          Cash
          <span className="absolute left-0 bottom-0">
            <img src={curvedLine} alt="" />
          </span>
        </span>{" "}
        To a <br /> Campers Canteen <br /> Account
      </h1>
    </div>
  );
};

export default HomeText;
