import React from "react";

const LabelValue = (props) => {
  return (
    <div className="flex flex-col items-start">
      <h6 className="text-[12px] sm:text-[14px] font-medium leading-[18px] sm:leading-[21px] text-[#191B259E]">
        {props.label}
      </h6>
      <h6 className="text-[14px] sm:text-[19px] font-bold leading-[21px] sm:leading-[28.5px] text-[#191B25] capitalize">
        {props.haveDollar && <span>&#36;</span>}
        {props.value}
      </h6>
    </div>
  );
};

export default LabelValue;
