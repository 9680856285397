import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAccountDetails,
  fetchAccountsByName,
  fetchBunks,
  setAccountStatus,
  setCamperDetails,
  setLoading,
} from "../../redux/accounts/accountSlice";
import { AccountStatus } from "../../data";
import SelectField from "../common/SelectField";
import InputField from "../common/InputField";
import Button from "../common/Button";
import SearchResults from "../common/SearchResults";

const AccountIdle = () => {
  const dispatch = useDispatch();
  const { camperDetails, camps, bunks, camperNames, status } = useSelector(
    (state) => state.account
  );

  const handleSearchAccount = () => {
    dispatch(
      fetchAccountDetails({
        userId: camperDetails.camp,
        accountId: camperDetails.name,
      })
    );
  };

  const handleChange = (id, value) => {
    dispatch(setCamperDetails({ [id]: value }));
    dispatch(
      fetchAccountsByName({
        selectedCampId: camperDetails.camp,
        namePrefix: value.toLowerCase(),
      })
    );
  };

  const onSelectChange = (id, value) => {
    if (id === "camp") {
      dispatch(
        setCamperDetails({
          camp: value,
          bunk: "",
          name: "",
        })
      );
      dispatch(fetchBunks(value));
    } else if (id === "bunk") {
      dispatch(
        setCamperDetails({
          ...camperDetails,
          bunk: value,
          name: "",
        })
      );
    } else if (id === "name") {
      dispatch(setCamperDetails({ ...camperDetails, name: value }));
    }
  };

  const isSearchFieldDisabled = !camperDetails.camp || !camperDetails.bunk;

  const isButtonDisabled =
    !camperDetails.camp || !camperDetails.bunk || !camperDetails.name;

  return (
    <div className="flex flex-col items-center w-full h-full px-5 sm:px-[60px]">
      <h2>Find Campers Account</h2>
      <h6 className="text-center">
        Ensure All Information Below is Accurate for Effective Search Results
      </h6>
      <div className="w-full mt-[18px] mb-[16px] sm:mb-[55px] flex flex-col gap-[16px]">
        <div className="flex flex-col sm:flex-row gap-5 justify-between w-full">
          <div className="w-full sm:w-1/2">
            <SelectField
              label="Select Camp"
              items={camps}
              onChange={onSelectChange}
              id="camp"
              value={camperDetails.camp}
              notRequired
              disabled={camps.length == 0}
            />
          </div>

          <div className="w-full sm:w-1/2">
            <SelectField
              label="Select Bunk"
              items={bunks}
              onChange={onSelectChange}
              id="bunk"
              value={camperDetails.bunk}
              notRequired
              disabled={!camperDetails.camp}
            />
          </div>
        </div>

        <div className="flex-grow">
          <SearchResults
            label="Campers Name"
            items={camperNames}
            onInputChange={handleChange}
            onItemSelect={onSelectChange}
            id="name"
            value={camperDetails.name}
            notRequired
            disabled={isSearchFieldDisabled}
            placeholder="Search your account name..."
          />
        </div>
      </div>
      <Button
        disabled={isButtonDisabled}
        isLoading={status === AccountStatus.SEARCHING}
        onClick={handleSearchAccount}
      >
        Find Account
      </Button>
    </div>
  );
};

export default AccountIdle;
