import React from "react";

const TextArea = ({ label, placeholder, id, value, onChange, notRequired }) => {
  return (
    <div className="flex flex-col space-y-[4px]">
      <label
        htmlFor={id}
        className={`${!notRequired && "required-input"}  text-[#000000] uppercase`}
        style={{
          fontFamily: "Poppins",
          fontWeight: 500,
          fontSize: "10px",
          lineHeight: "15px",
          paddingLeft: "20px",
        }}
      >
        {label}
      </label>
      <div>
        <textarea
          id={id}
          rows={3}
          value={value}
          placeholder={placeholder}
          className="w-full pl-[20px] p-4 rounded-lg bg-inputBackground border border-inputBackground focus:outline-none focus:border-primary focus:ring-primary text-[14px] font-normal"
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default TextArea;
