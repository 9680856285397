import React, { useRef } from "react";
import { useJsApiLoader, StandaloneSearchBox } from "@react-google-maps/api";
import { useDispatch } from "react-redux";
import { setSenderDetails } from "../../redux/accounts/accountSlice";

const LIBRARIES = ["places"];
const GoogleAPIAddressField = ({
  id,
  notRequired,
  labelColor,
  disabled = false,
  error,
}) => {
  const dispatch = useDispatch();
  const searchBoxRef = useRef(null);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLEMAPS_API_KEY,
    libraries: LIBRARIES,
  });

  const handleOnPlacesChanged = () => {
    if (!searchBoxRef.current) return;
    let address = searchBoxRef.current.getPlaces();

    if (address && address.length > 0) {
      const place = address[0];

      let street = "";
      let streetNumber = "";
      let city = "";
      let state = "";
      let zipCode = "";
      let country = "";

      place.address_components.forEach((component) => {
        const componentType = component.types[0];

        switch (componentType) {
          case "street_number": {
            streetNumber = component.long_name;
            break;
          }
          case "route": {
            street = component.long_name;
            break;
          }
          case "postal_code": {
            zipCode = component.long_name;
            break;
          }
          case "locality":
          case "administrative_area_level_3":
          case "sublocality_level_1": {
            city = component.long_name;
            break;
          }
          case "administrative_area_level_1": {
            state = component.short_name;
            break;
          }
          case "country": {
            country = component.long_name;
            break;
          }
        }
      });

      const fullStreet = streetNumber ? `${streetNumber} ${street}` : street;

      dispatch(
        setSenderDetails({
          xBillStreet: fullStreet,
          xBillCity: city,
          xBillState: state,
          xBillZip: zipCode,
          xBillCountry: country,
        })
      );
    }
  };

  const handleInputChange = (e) => {
    dispatch(setSenderDetails({ xBillStreet: e.target.value }));
  };

  return (
    <div
      className={`mt-[16px] space-y-[3px] ${disabled ? "item-disabled" : ""}`}
    >
      <label
        htmlFor={id}
        className={`${!notRequired && "required-input"} ${
          labelColor ? `${labelColor} uppercase` : "text-primary"
        }`}
        style={{
          fontFamily: "Poppins",
          fontWeight: 500,
          fontSize: "10px",
          lineHeight: "15px",
          paddingLeft: "20px",
        }}
      >
        billing address
      </label>
      {isLoaded && (
        <StandaloneSearchBox
          onLoad={(ref) => (searchBoxRef.current = ref)}
          onPlacesChanged={handleOnPlacesChanged}
        >
          <input
            id={id}
            type="text"
            placeholder=""
            className={`w-full h-[33px] pl-[20px] p-4 rounded-lg bg-inputBackground border border-inputBackground focus:outline-none focus:border-primary focus:ring-primary text-[14px] font-normal ${
              disabled ? "cursor-not-allowed" : "cursor-text"
            }`}
            onChange={handleInputChange}
            disabled={disabled}
          />
        </StandaloneSearchBox>
      )}
      {error && (
        <p
          style={{
            color: "#D22727",
            fontWeight: "400",
            fontSize: "11px",
            lineHeight: "16.5px",
          }}
        >
          {error}
        </p>
      )}
    </div>
  );
};

export default GoogleAPIAddressField;
