import React from "react";
import { AccountStatus } from "../../data";
import AccountNotFound from "./AccountNotFound";
import LoadingOverlay from "../common/LoadingOverlay";
import AccountIdle from "./AccountIdle";
import AccountFound from "./AccountFound";
import { useSelector } from "react-redux";
import AccountSuccess from "./AccountSuccess";

const AddCash = ({ onClose }) => {
  const { status, isLoading } = useSelector((state) => state.account);

  const renderContent = () => {
    switch (status) {
      case AccountStatus.IDLE:
        return <AccountIdle />;
      case AccountStatus.NOT_FOUND:
        return <AccountNotFound />;
      case AccountStatus.FOUND:
        return <AccountFound />;
      case AccountStatus.SUCCESS:
        return <AccountSuccess onClose={onClose} />;
      default:
        return <AccountIdle />;
    }
  };

  return (
    <div className="w-full pb-5">
      {renderContent()}
      <LoadingOverlay isLoading={isLoading} />
    </div>
  );
};

export default AddCash;
