import React, { useState } from "react";
import { CgSpinner } from "react-icons/cg";

const Button = ({ children, onClick, disabled, isLoading, normalButton }) => {
  const [isPressed, setIsPressed] = useState(false);

  const handleMouseDown = () => {
    setIsPressed(true);
  };

  const handleMouseUp = () => {
    setIsPressed(false);
  };

  return (
    <button
      disabled={disabled}
      className={`
        relative
        ${
          normalButton
            ? "w-[140px] lg:w-[171px] lg:h-[87.51px] h-[56.79px] rounded-[96px]"
            : "h-[41px] lg:h-[52px] rounded-[116px] px-5"
        }
        text-white
        font-semibold
        transition-all
        duration-200
        overflow-hidden
        transform
        ${isPressed ? "scale-95" : "scale-100"}
        ${disabled ? "" : "hover:scale-105"}
        disabled:opacity-50 disabled:cursor-not-allowed disabled:bg-white
      `}
      style={{
        background:
          "linear-gradient(99.78deg, #E06F2C 24.73%, #FFB570 109.55%)",
        boxShadow: isPressed
          ? "0px 1px 10px 0px #00000029"
          : "0px 3px 21px 0px #00000029",
      }}
      onClick={onClick}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseLeave={() => setIsPressed(false)}
    >
      {/* Gradient overlay for hover effect */}
      <div
        className={`
          absolute 
          inset-0 
        ${normalButton ? "rounded-[96px]" : "rounded-[116px]"}
          transition-opacity 
          duration-200
          ${isPressed ? "opacity-20" : "opacity-0"}
          ${disabled ? "" : "hover:opacity-10"}
        `}
        style={{
          background:
            "linear-gradient(99.78deg, #FFB570 24.73%, #E06F2C 109.55%)",
        }}
      />

      {/* Button content */}
      <div
        className={`
        relative 
        z-10 
        flex 
        items-center 
        justify-center 
        w-full 
        h-full
        transition-transform 
        duration-200
        ${isPressed ? "transform translate-y-0.5" : ""}
        ${
          normalButton
            ? "lg:text-[38px] text-[24px] lg:leading-[57px] leading-[36px]"
            : "lg:text-[20px] text-[17px] lg:leading-[30px] leading-[25.5px]"
        }
        font-semibold
        gap-2
      `}
      >
        {isLoading && <CgSpinner size="20" className="animate-spin" />}
        {isLoading ? "Please wait..." : children}
      </div>
    </button>
  );
};

export default Button;
