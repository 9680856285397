import React from "react";
import Button from "../common/Button";
import { useDispatch } from "react-redux";
import {
  resetAccount,
  setAccountStatus,
} from "../../redux/accounts/accountSlice";
import { AccountStatus } from "../../data";

const AccountNotFound = () => {
  const dispatch = useDispatch();

  const handleTryAgain = () => {
    dispatch(resetAccount());
    dispatch(setAccountStatus(AccountStatus.IDLE));
  };

  return (
    <div className="flex flex-col items-center w-full h-full px-5 sm:px-[60px]">
      <h2>Account Not Found</h2>
      <h6 className="text-center mb-[70px] text-[17px] text-normal text-[#6F6F6F]">
        We couldn’t find the account you’re looking for.
        <br /> Please check your details
      </h6>
      <Button onClick={handleTryAgain}>Try Again</Button>
    </div>
  );
};

export default AccountNotFound;
