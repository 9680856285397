import React from "react";
import Logo from "../component/common/Logo";

const DrawerList = ({ navLinks }) => {
  return (
    <div className="bg-white w-[18rem]">
      <div className="flex justify-center items-center h-[62px] border-b border-gray-300 bg-black">
        <Logo />
      </div>

      <div className="flex flex-col items-center justify-between h-full my-5">
        <ul>
          {navLinks.map((link, index) => (
            <li key={index}>
              <a
                className="flex items-center justify-center px-6"
                href={`/${link.to}`}
                {...(link.label === "Add Balance to Accounts"
                  ? {}
                  : { target: "_blank", rel: "noopener noreferrer" })}
              >
                <span
                  style={{
                    lineHeight: "46px",
                    fontWeight: "600",
                    fontSize: "18px",
                    color: "#767676",
                  }}
                >
                  {link.label}
                </span>
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default DrawerList;
