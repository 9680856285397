import IField, {
  CARD_TYPE,
  CVV_TYPE,
  ACH_TYPE,
  THREEDS_ENVIRONMENT,
} from "@cardknox/react-ifields";
import React, { forwardRef, useImperativeHandle, useRef } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  resetCardToken,
  resetCvvToken,
  setCardInfo,
} from "../../redux/cards/cardSlice";

const WrappedIfield = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const { ifieldKey } = useSelector((state) => state.card);

  const internalRef = useRef();
  useImperativeHandle(
    ref,
    () => ({
      getToken() {
        internalRef.current.getToken();
      },
      focusIfield() {
        internalRef.current.focusIfield();
      },
      clearIfield() {
        internalRef.current.clearIfield();
      },
    }),
    []
  );

  const {
    ifieldType,
    issuer,
    onIssuer,
    onToken,
    onError,
    handle3DSResults,
    ifieldStyle,
  } = props;

  const account = {
    xKey: ifieldKey,
    xSoftwareName: "camp-pos",
    xSoftwareVersion: "1.0.0",
  };

  const options = {
    enableLogging: false,
    autoSubmit: false,
    placeholder: ifieldType === CARD_TYPE ? "1234 1234 1234 1234" : "***",
    autoFormat: true,
    autoFormatSeparator: " ",
    blockNonNumericInput: true,
    iFieldstyle: ifieldStyle,
    autoSubmit: true,
  };

  const onLoad = () => {
    console.log("Iframe loaded");
  };

  const onUpdate = (data) => {
    if (ifieldType === CARD_TYPE) {
      if (data.isValid) {
        dispatch(setCardInfo({ cardIssuer: data.issuer }));
      } else {
        dispatch(resetCardToken());
      }
    }

    if (ifieldType === CARD_TYPE && data.issuer) onIssuer(data.issuer);

    if (ifieldType === CVV_TYPE && data.isValid === false) {
      dispatch(resetCvvToken());
    }
  };

  const onSubmit = (data) => {
    console.log("IFrame submitted", data);
  };

  const threeds = {
    enable3DS: true,
    environment: THREEDS_ENVIRONMENT.Staging,
    handle3DSResults: handle3DSResults,
  };
  return (
    <IField
      type={ifieldType}
      account={account}
      options={options}
      threeDS={ifieldType === CARD_TYPE ? threeds : null}
      onLoad={onLoad}
      onUpdate={onUpdate}
      onSubmit={onSubmit}
      onToken={onToken}
      ref={internalRef}
      onError={onError}
      issuer={issuer}
      className="h-[33px]"
    />
  );
});

WrappedIfield.propTypes = {
  ifieldType: PropTypes.oneOf([CARD_TYPE, CVV_TYPE, ACH_TYPE]),
  issuer: PropTypes.string,
  onIssuer: PropTypes.func,
  onToken: PropTypes.func,
  onError: PropTypes.func,
  handle3DSResults: PropTypes.func,
  ifieldStyle: PropTypes.object,
};

export default WrappedIfield;
