import React from "react";
import Button from "../common/Button";
import { useDispatch, useSelector } from "react-redux";
import { resetAccount } from "../../redux/accounts/accountSlice";
import { capitalizeWords, findCampById } from "../../utils";

const AccountSuccess = ({ onClose }) => {
  const dispatch = useDispatch();
  const { senderDetails, accountData } = useSelector((state) => state.account);

  const handleSuccess = () => {
    dispatch(resetAccount());
    onClose();
  };

  return (
    <div className="flex flex-col items-center w-full h-full px-5 sm:px-[60px]">
      <h2>Success!</h2>
      <h6 className="text-center mb-[70px] text-[17px] text-normal text-[#6F6F6F]">
        You’ve added &#36;{senderDetails.amount} to
        <br />
        <span className="capitalize">
          {capitalizeWords(accountData?.name)}'s
        </span>{" "}
        account.
      </h6>
      <Button onClick={handleSuccess}>Done</Button>
    </div>
  );
};

export default AccountSuccess;
