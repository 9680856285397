import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../api/axios";

export const getCardknoxIfieldKey = createAsyncThunk(
  "getCardknoxIfieldKey",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get("/api/payment/config");
      return response.data.data.ifieldKey;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

const initialState = {
  ifieldKey: null,
  cardError: null,
  cardLoading: false,
  gatewayResponse: "",
  cardInfo: {
    cardToken: "",
    cardIssuer: "",
    expiry: "",
    cvvToken: "",
  },
};

const cardSlice = createSlice({
  name: "card",
  initialState,
  reducers: {
    setCardInfo: (state, action) => {
      state.cardInfo = { ...state.cardInfo, ...action.payload };
    },
    resetCardToken: (state, action) => {
      state.cardInfo.cardToken = "";
      state.cardInfo.cardIssuer = "";
    },
    resetCvvToken: (state, action) => {
      state.cardInfo.cvvToken = "";
    },
    setGatewayResponse: (state, action) => {
      state.gatewayResponse = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getCardknoxIfieldKey.pending, (state) => {
      state.cardLoading = true;
      state.cardError = null;
    });
    builder.addCase(getCardknoxIfieldKey.fulfilled, (state, action) => {
      state.cardLoading = false;
      state.ifieldKey = action.payload;
    });
    builder.addCase(getCardknoxIfieldKey.rejected, (state, action) => {
      state.cardLoading = false;
      state.cardError = action.payload;
    });
  },
});

export const {
  setCardInfo,
  resetCardToken,
  resetCvvToken,
  setGatewayResponse,
} = cardSlice.actions;

export default cardSlice.reducer;
