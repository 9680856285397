import React, { useEffect, useState } from "react";
import { IoMenuOutline } from "react-icons/io5";
import Logo from "../component/common/Logo";
import sort from "../assets/icons/Sort.png";
import { Drawer, ThemeProvider, createTheme } from "@mui/material";
import DrawerList from "./DrawerList";

const theme = createTheme();

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeNavItem, setActiveNavItem] = useState("");

  const navLinks = [
    { label: "Add Balance to Accounts", to: "" },
    { label: "About", to: "about" },
    { label: "POS Login", to: "/admin.camppos.app" },
  ];

  useEffect(() => {
    const currentPath = window.location.pathname;

    if (currentPath !== "/") {
      setActiveNavItem(currentPath.substring(1));
    }
  }, []);

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsOpen(open);
  };

  return (
    <ThemeProvider theme={theme}>
      <nav
        className={`w-full h-[62px] lg:h-[113px] fixed top-0 z-50`}
        style={{
          background: "#1F2022",
          boxShadow: "0px 14px 34px 0px #00000040",
        }}
      >
        <div className="flex justify-center lg:justify-between items-center h-full  mx-auto px-6 lg:px-[100px] relative">
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="lg:hidden text-white focus:outline-none h-8 w-8 hover:bg-gray-100 rounded-md flex items-center justify-center absolute left-[23px]"
          >
            <img src={sort} alt="sort" className="w-[24px] h-[24px]" />
          </button>

          <Logo />

          <ul className="hidden lg:flex justify-center gap-[50px]">
            {navLinks.map((link, index) => (
              <a
                key={index}
                href={`/${link.to}`}
                {...(link.label === "Add Balance to Accounts"
                  ? {}
                  : { target: "_blank", rel: "noopener noreferrer" })}
              >
                <span
                  style={{
                    lineHeight: "46px",
                    fontWeight: "600",
                    fontSize: "18px",
                    color: activeNavItem === link.to ? "#E4E4E4" : "#767676",
                  }}
                >
                  {link.label}
                </span>
              </a>
            ))}
          </ul>
        </div>
        <Drawer
          anchor="left"
          open={isOpen}
          onClose={toggleDrawer(false)}
          className="lg:hidden"
        >
          <DrawerList navLinks={navLinks} />
        </Drawer>
      </nav>
    </ThemeProvider>
  );
};

export default Navbar;
