import React, { useRef, useState } from "react";
import WrappedIfield from "./WrappedIfield";
import { CARD_TYPE, CVV_TYPE } from "@cardknox/react-ifields";
import axios from "../../api/axios";
import { useDispatch } from "react-redux";
import { setCardInfo } from "../../redux/cards/cardSlice";

const PaymentForm = ({ formErrors }) => {
  const dispatch = useDispatch();
  const [expiry, setExpiry] = useState("");
  const [issuer, setIssuer] = useState("");
  const [gateway3dsResponse, setGateway3dsResponse] = useState("");
  const cardRef = useRef();
  const cvvRef = useRef();

  const getCardToken = () => {
    cardRef.current.getToken();
  };

  const getCvvToken = () => {
    cvvRef.current.getToken();
  };

  const clearCard = () => {
    cardRef.current.clearIfield();
  };

  const clearCvv = () => {
    cvvRef.current.clearIfield();
  };

  const onCardToken = (data) => {
    dispatch(setCardInfo({ cardToken: data.xToken }));
  };

  const onCvvToken = (data) => {
    dispatch(setCardInfo({ cvvToken: data.xToken }));
  };

  const handleExpiryChange = (e) => {
    const { id, value } = e.target;
    const numericValue = value.replace(/\D/g, "");

    let formattedValue = "";
    let month = "";
    let year = "";
    if (numericValue.length > 0) {
      month = numericValue.slice(0, 2);
      const monthNum = parseInt(month, 10);

      if (monthNum > 12) return;

      year = numericValue.slice(2, 4);

      if (numericValue.length <= 2) {
        formattedValue = month;
      } else if (numericValue.length > 2) {
        formattedValue = `${month} / ${year}`;
      }
    }

    const xExp = `${month}${year}`;

    dispatch(setCardInfo({ [id]: xExp }));

    setExpiry(formattedValue);
  };

  const handle3DSResults = async (
    actionCode,
    xCavv,
    xEciFlag,
    xRefNum,
    xAuthenticateStatus,
    xSignatureVerification,
    error
  ) => {
    try {
      console.log("handle3DSResults");
      const postData = {
        xSoftwareName: "camp-pos",
        xSoftwareVersion: "1.0.0",
        xVersion: "5.0.0",
        x3dsError: error,
        xRefNum: xRefNum,
        xCavv: xCavv,
        xEci: xEciFlag,
        x3dsAuthenticationStatus: xAuthenticateStatus,
        x3dsSignatureVerificationStatus: xSignatureVerification,
        x3dsActionCode: actionCode,
      };
      const response = await axios.post("/api/payment/verifyjson", postData);
      setGateway3dsResponse(await response.json());
    } catch (error) {
      console.error(error);
      setGateway3dsResponse(error);
    }
  };

  const iFieldStyle = {
    borderRadius: "5px",
    background: "#F2F2F2",
    border: "none",
    height: "33px",
    outline: "none",
    paddingLeft: "21px",
    paddingRight: "10px",
    fontWeight: 400,
    fontFamily: "Poppins",
    fontSize: "14px",
    lineHeight: "21px",
    width: "100%",
    boxSizing: "border-box",
    overflow: "hidden",
  };

  return (
    <section className="w-full mt-[20px]">
      <div className="flex flex-col sm:flex-row gap-[20px] sm:gap-[16px]">
        <div className="flex flex-col justify-between w-full sm:w-[50%] relative">
          <label
            htmlFor="card-number"
            className="required-input uppercase text-[10px] leading-[15px] font-medium pl-[17px] text-[#000000] block"
          >
            Card Number
          </label>
          <WrappedIfield
            ifieldType={CARD_TYPE}
            onIssuer={setIssuer}
            onToken={onCardToken}
            handle3DSResults={handle3DSResults}
            ref={cardRef}
            ifieldStyle={iFieldStyle}
          />
          {formErrors.cardToken && (
            <p
              className="absolute bottom-[-20px]"
              style={{
                color: "#D22727",
                fontWeight: "400",
                fontSize: "11px",
                lineHeight: "16.5px",
              }}
            >
              {formErrors.cardToken}
            </p>
          )}
        </div>
        <div className="flex flex-row justify-between w-full sm:w-[50%] gap-[10px]">
          <div className="flex flex-col space-y-[3px] relative">
            <label
              htmlFor="expiry"
              className="required-input uppercase text-[10px] leading-[15px] font-medium pl-[17px] text-[#000000] block"
            >
              Expiry
            </label>
            <input
              id="expiry"
              type="text"
              placeholder="MM / YY"
              value={expiry}
              onChange={handleExpiryChange}
              maxLength={7}
              style={iFieldStyle}
              inputMode="numeric"
              autoComplete="cc-exp"
            />
            {formErrors.expiry && (
              <p
                className="absolute bottom-[-20px]"
                style={{
                  color: "#D22727",
                  fontWeight: "400",
                  fontSize: "11px",
                  lineHeight: "16.5px",
                }}
              >
                {formErrors.expiry}
              </p>
            )}
          </div>
          <div className="flex flex-col justify-between max-w-[50%] relative">
            <label
              htmlFor="cvv"
              className="required-input uppercase text-[10px] leading-[15px] font-medium pl-[17px] text-[#000000] block"
            >
              CVV
            </label>
            <WrappedIfield
              ifieldType={CVV_TYPE}
              issuer={issuer}
              onToken={onCvvToken}
              ref={cvvRef}
              ifieldStyle={iFieldStyle}
            />
            {formErrors.cvvToken && (
              <p
                className="absolute bottom-[-20px]"
                style={{
                  color: "#D22727",
                  fontWeight: "400",
                  fontSize: "11px",
                  lineHeight: "16.5px",
                }}
              >
                {formErrors.cvvToken}
              </p>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default PaymentForm;
