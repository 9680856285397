import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";

const Logo = ({ to }) => {
  return (
    <Link to={to}>
      <img src={logo} alt="logo" className="w-[192px] h-[62px]" />
    </Link>
  );
};

export default Logo;
